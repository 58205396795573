import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { AUTHORIZATION_SESSION_KEY, CoreConstants, CoreConstantsApi, PasswordCriteria, UserMessage } from '@core/core.constants';
import { ToasterService } from '@shared/services/toaster.service';
import { filter, first, takeUntil } from 'rxjs/operators';
import { OTPStatus } from '@modules/login/models/login.model';
import { CustomValidator } from '@shared/validators/custom-pattern.validator';
import { UpdatePasswordService } from './services/update-password.service';
import { PasswordCheck, PasswordCheckResponse, UpdatePasswordRequest, UpdatePwdUserDetails, CreatePasswordRequest, ValidateCreateUser, PasswordTwoStepAuthDetails, UpdatePwdInfo } from './models/update-password.model';
import { RouteURLS } from '@core/core.constants';
import { Subject } from 'rxjs';
import { LoaderService } from '@shared/services/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ROLES } from '@auth/model/roles.enum';
import { AESEncryptDecryptService } from '@core/storage/storage';
import { ForgotPasswordService } from '@modules/forgot-password/services/forgot-password.service';

@Component({
    selector: 'app-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
    lastFourDigit = '';
    sendOtpStatus = false;
    otpEnter = '';
    disabledOtp = true;
    userDetails: any = {
        userName: '',
        password: '',
        rememberMe: false,
    };
    addBorder = '';
    selectedUserDetail = '';
    enter: boolean = false;
    showPassword = true;
    showNewPasswordContent = false;
    timeLeft: number = CoreConstants.TwilioMFATimeout;
    interval: any;
    updatePassForm: FormGroup;
    authenticationUserId: string = '';
    private unSubscribe = new Subject();
    showCurrentPassword: boolean = true;
    showNewPassword: boolean = true;
    cardTitle: string = '';
    createNewPassForm: FormGroup;
    currentRoute: string = '';
    routeURLS = RouteURLS;
    createUserToken: any = '';
    gatewayclient:string =CoreConstants.gatewayclient;
    constructor(
        private authService: AuthService,
        private fb: FormBuilder,
        private router: Router,
        private toasterService: ToasterService,
        private updateUserService: UpdatePasswordService,
        private activeRoute: ActivatedRoute,
        private loaderService: LoaderService,
        private crypto: AESEncryptDecryptService,
        private forgotPass: ForgotPasswordService,
    ) {
        this.updatePassForm = this.fb.group(
            {
                currentPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(14),
                    CustomValidator.CustomPatternValidator(PasswordCriteria.passwordRegex, { hasCriteriaMatch: true }),
                ])),
                newPassword: new FormControl(
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(14),
                        CustomValidator.CustomPatternValidator(PasswordCriteria.passwordRegex, { hasCriteriaMatch: true }),
                    ]),
                ),
                confirmNewPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(14)])),
            },
            {
                validator: CustomValidator.MustMatch('newPassword', 'confirmNewPassword'),
            },
        );
        this.createNewPassForm = this.fb.group(
            {
                userName: new FormControl(
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.email,
                        Validators.pattern(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        ),
                    ]),
                ),
                newPassword: new FormControl(
                    '',
                    Validators.compose([
                        Validators.required,
                        Validators.minLength(14),
                        CustomValidator.CustomPatternValidator(PasswordCriteria.passwordRegex, { hasCriteriaMatch: true }),
                    ]),
                ),
                confirmNewPassword: new FormControl('', Validators.compose([Validators.required, Validators.minLength(14)])),
            },
            {
                validator: CustomValidator.MustMatch('newPassword', 'confirmNewPassword'),
            },
        );
        this.router.events.pipe(filter(navigate => navigate instanceof NavigationEnd)).subscribe((route: any) => {
            this.currentRoute = route.url;
            if (route.url.includes(RouteURLS.updatePassword)) {
                this.cardTitle = 'Update Password';
            } else {
                this.cardTitle = 'Create Password';
            }
        });
        
    }

    ngOnInit(): void {
        if (this.currentRoute.includes(RouteURLS.updatePassword)) {
            if (this.authService.userLoginDetails()) {
                this.showUserLoginDetails(this.authService.userLoginDetails());
            } else {
                const userDetails: string | null = sessionStorage.getItem(CoreConstants.userDetails) as string;
                const currentUserName: string = localStorage.getItem(CoreConstants.userDetails) as string;
                const { userName } = JSON.parse(this.crypto.decrypt(userDetails));
                if(typeof(userName) !== undefined || userName !== '') {
                    this.userDetails.userName = userName;
                } else {
                    this.userDetails.userName = currentUserName;
                }
            }
        }
        if (this.currentRoute.includes(RouteURLS.createPassword)) {
            this.loaderService.showLoader('create-password');
            this.activeRoute.queryParamMap.subscribe((params: any) => {
                this.createUserToken = params.get('token');
            });
            if (this.createUserToken !== '') {
                sessionStorage.setItem(AUTHORIZATION_SESSION_KEY, this.createUserToken);
                this.authService.isAuthenticationCompleted$.next(true);
                this.authService.getUserName().subscribe( userName => {
                    this.createNewPassForm.patchValue({ userName: userName });
                this.userDetails.userName = userName;
                })
                this.loaderService.hideLoader('create-password');
                AuthService.setUserName(this.userDetails.userName);
            }
        }
    }

    showUserLoginDetails(loginDetails: UpdatePwdUserDetails): void {
        this.userDetails.userName = loginDetails.userName;
    }

    onOtpChange(event: string): void {
        if (event.length === 6) {
            this.otpEnter = event;
            this.enter = true;
        }
        if (event.length < 6) {
            this.otpEnter = '';
        }
    }

    enterKeyTwoStepSubmit(): void {
        if (this.enter == true) this.verifyOtp();
    }

    verificationMethod(selectedMethod: string, userDetail: string): void {
        this.addBorder = selectedMethod;
        this.selectedUserDetail = userDetail;
        this.updateUserService
            .getOTPVerify(CoreConstantsApi.sendOtp, { to: userDetail, channel: selectedMethod })
            .pipe(first(), takeUntil(this.unSubscribe))
            .subscribe({
                next: (sendOtp: OTPStatus) => {
                    this.showNewPasswordContent = sendOtp.isSent;
                    if (selectedMethod === 'email') {
                        this.lastFourDigit = this.selectedUserDetail;
                    }
                    this.showTimer();
                },
                error: (error: HttpErrorResponse) => {
                    if (error) {
                        this.toasterService.toastFailure(UserMessage.LockedUserMessage, 1500, 'toast-right');
                        // this.toasterService.toastFailure(error.error, 3000, 'toast-right');
                    }
                },
            });
    }

    showTimer(): void {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
                document.getElementById(CoreConstants.countdown)!.innerHTML = `The code will expire in ${this.timeLeft} seconds.`;
            } else {
                this.disabledOtp = false;
                this.timeLeft = CoreConstants.TwilioMFATimeout;
                clearInterval(this.interval);
                document.getElementById(CoreConstants.countdown)!.innerHTML = CoreConstants.codeExpired;
            }
        }, 1000);
    }

    sendOtp(): void {
        this.disabledOtp = true;
        this.otpEnter = '';
        this.verificationMethod(this.addBorder, this.selectedUserDetail);
    }

    verifyOtp(): void {
        if (this.isUpdatePasswordURL) {
            const loginBody: UpdatePasswordRequest = {
                to: this.selectedUserDetail,
                otp: this.otpEnter,
                userName: this.userDetails.userName,
                newPassword: this.updatePassForm.value.newPassword,
                oldPassword: this.updatePassForm.value.currentPassword,
                authenticationId: this.authenticationUserId,
            };
            this.updateUserService
                .updateUserPassword(CoreConstantsApi.updateUserPassword, loginBody)
                .pipe(first(), takeUntil(this.unSubscribe))
                .subscribe(
                    (val: UpdatePwdInfo) => {
                        if (val.status) {

                            this.toasterService.toastSuccess(UserMessage.PasswordUpdateMessage, 1500, 'toast-right');
                            this.onRedirectHome(val.verificationResult);
                        } else {
                            this.toasterService.toastFailure(UserMessage.InvalidOTP,  3000, 'toast-right')
                
                        }
                    },
                    () => {},
                );
        } else {
            const loginBody: CreatePasswordRequest = {
                to: this.selectedUserDetail,
                otp: this.otpEnter,
                userName: this.createNewPassForm.value.userName,
                createPassword: this.createNewPassForm.value.newPassword,
                token: this.createUserToken,
            };
            this.updateUserService
                .onCreateUserPassword(loginBody)
                .pipe(first(), takeUntil(this.unSubscribe))
                .subscribe(
                    (val: UpdatePwdInfo) => {
                        if (val.status) {
                            this.toasterService.toastSuccess(UserMessage.PasswordCreateMessage, 1500, 'toast-right');
                            this.onRedirectHome(val.verificationResult);
                        } else {
                            this.toasterService.toastFailure(val.message, 3000, 'toast-right');
                        }
                    },
                    () => {},
                );
        }
    }

    onPasswordToggle(): void {
        this.showPassword = !this.showPassword;
    }

    onSubmit(): void {
        if (this.isUpdatePasswordURL) {
            this.onVerifyUserPreviousPass();
        } else {

        const passwordDetails: PasswordCheck = {
                userName: this.userDetails.userName,
                oldPassword: '',
                newPassword: this.createNewPassForm.value.newPassword
        };

            this.forgotPass.verifyForgotPreviousPassword(passwordDetails)
            .subscribe({
                next: (response: PasswordCheckResponse) => {
                    if (response.status) {
                        if (this.isUpdatePasswordURL) {
                            this.onVerifyUserPreviousPass();
                        } else {
                            this.sendOtpStatus = true;
                        }
                    } else {
                        this.sendOtpStatus = false;
                        this.toasterService.toastFailure(response.message, 3000, 'toast-right');
                    }
                },
                error: () => {
                    this.sendOtpStatus = false;
                },     
            });
        }
    }

    get getConfirmPasswordError() {
        return (
            (this.updatePassForm.controls['confirmNewPassword'].invalid && this.updatePassForm.controls['confirmNewPassword'].dirty) ||
            (this.updatePassForm.controls['confirmNewPassword'].touched && this.updatePassForm.controls['confirmNewPassword'].errors?.required) ||
            this.updatePassForm.controls['confirmNewPassword'].errors?.minlength ||
            this.updatePassForm.controls['confirmNewPassword'].errors?.mustMatch
        );
    }

    get getPasswordError() {
        return (
            (((this.updatePassForm.controls['newPassword'].invalid && this.updatePassForm.controls['newPassword'].dirty) || this.updatePassForm.controls['newPassword'].touched) &&
                (this.updatePassForm.controls['newPassword'].errors?.required || this.updatePassForm.controls['newPassword'].errors?.minlength)) ||
            this.updatePassForm.controls['newPassword'].hasError('hasCriteriaMatch')
        );
    }

    get getCurrentPasswordError() {
        return (
            (this.updatePassForm.controls['currentPassword'].invalid && this.updatePassForm.controls['currentPassword'].dirty) ||
            (this.updatePassForm.controls['currentPassword'].touched && this.updatePassForm.controls['currentPassword'].errors?.required) ||
            this.updatePassForm.controls['currentPassword'].errors?.minlength
        );
    }

    get getUserNameError() {
        return (
            (this.createNewPassForm.controls['userName'].invalid && this.createNewPassForm.controls['userName'].dirty) ||
            (this.createNewPassForm.controls['userName'].touched && this.createNewPassForm.controls['userName'].errors?.required) ||
            this.createNewPassForm.controls['userName'].errors?.pattern
        );
    }

    get getConfirmNewPasswordError() {
        return (
            (this.createNewPassForm.controls['confirmNewPassword'].invalid && this.createNewPassForm.controls['confirmNewPassword'].dirty) ||
            (this.createNewPassForm.controls['confirmNewPassword'].touched && this.createNewPassForm.controls['confirmNewPassword'].errors?.required) ||
            this.createNewPassForm.controls['confirmNewPassword'].errors?.minlength ||
            this.createNewPassForm.controls['confirmNewPassword'].errors?.mustMatch
        );
    }

    get getNewPasswordError() {
        return (
            (((this.createNewPassForm.controls['newPassword'].invalid && this.createNewPassForm.controls['newPassword'].dirty) ||
                this.createNewPassForm.controls['newPassword'].touched) &&
                (this.createNewPassForm.controls['newPassword'].errors?.required || this.createNewPassForm.controls['newPassword'].errors?.minlength)) ||
            this.createNewPassForm.controls['newPassword'].hasError('hasCriteriaMatch')
        );
    }

    onVerifyUserPreviousPass(): void {
        const passwordDetails: PasswordCheck = {
            userName: this.userDetails.userName,
            oldPassword: this.updatePassForm.value.currentPassword,
            newPassword: this.updatePassForm.value.newPassword,
        };
        this.updateUserService.verifyPreviousUserPassword(passwordDetails).subscribe({
            next: (response: PasswordCheckResponse) => {
                if (response.status && response.authenticationId !== null) {
                    this.sendOtpStatus = true;
                    this.authenticationUserId = response.authenticationId;
                } else {
                    this.sendOtpStatus = false;
                    this.toasterService.toastFailure(response.message, 3000, 'toast-right');
                }
            },
            error: () => {
                this.sendOtpStatus = false;
            },
        });
    }

    onCurrentPasswordToggle(): void {
        this.showCurrentPassword = !this.showCurrentPassword;
    }

    onNewPasswordToggle(): void {
        this.showNewPassword = !this.showNewPassword;
    }

    onLogoutAfterUpdate(): void {
        this.authService.isAuthenticationCompleted$.next(false);
        this.router.navigateByUrl(CoreConstants.externalLoginPath);
        AuthService.clearSessionStorage();
    }

    get isUpdatePasswordURL(): boolean {
        return this.currentRoute.includes(RouteURLS.updatePassword) ? true : false;
    }

    onValidateUserName(): void {
        this.sendOtpStatus = true;
    }

    onVerifyUserToken(token: ValidateCreateUser): void {
        if (token.status) {
            sessionStorage.setItem(AUTHORIZATION_SESSION_KEY, this.createUserToken);
            this.authService.isAuthenticationCompleted$.next(true);
            this.createNewPassForm.patchValue({ userName: token.username });
            this.userDetails.userName = token.username;
            AuthService.setUserName(this.userDetails.userName);
            this.loaderService.hideLoader('create-password');
        }
    }

	onRedirectHome(otpStatus: PasswordTwoStepAuthDetails): void {
		if (otpStatus?.isValid) {
			AuthService.setAuthorizationToken(otpStatus.token);
			AuthService.setUserName(this.userDetails.userName);
			AuthService.setUserAuthDetails({ authorizationToken: otpStatus.token, role: otpStatus.role as ROLES, viewMultiDealScreen: otpStatus.viewMultiDealScreen, multiDealScreenCapabilities: otpStatus.multiDealScreenCapabilities });
			this.authService.isAuthenticationCompleted$.next(true);
			this.router.navigateByUrl(CoreConstants.dashboardPath);
			sessionStorage.setItem('previewInfo', JSON.stringify(null));
			this.authService.isPreviewUser.next(false);
		}
	}

    ngOnDestroy(): void {
        clearInterval(this.interval);
        this.unSubscribe.next();
        this.unSubscribe.complete();
    }
}
